import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import './all.sass'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet title="Blum Ministries | Kirjakauppa">
      <link href="https://fonts.googleapis.com/css?family=Quicksand:400,600&display=swap" rel="stylesheet" />
    </Helmet>  
    <Navbar />

    <section className="hero _is-primary is-medium">
      {/* Hero head: will stick at the top */}
      <div className="hero-head">
        <nav className="navbar">
          <div className="container">
            <div className="navbar-brand">
              {/*
              <a className="navbar-item">
                <img src="https://bulma.io/images/bulma-type-white.png" alt="Logo" />
              </a>
               */}
              <a className="navbar-item" href="https://holvi.com/shop/juliablum/">
                Kirjakauppa
              </a> 
              <span className="navbar-burger burger" data-target="navbarMenuHeroA">
                <span />
                <span />
                <span />
              </span>
            </div>
            <div id="navbarMenuHeroA" className="navbar-menu">
              <div className="navbar-end">
              {/*
                <a className="navbar-item" href="https://holvi.com/shop/juliablum/">
                  Kirjakauppa
                </a>
                <a className="navbar-item is-active">
                  Examples
                </a>
                <a className="navbar-item">
                  Documentation
                </a>
                */}
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* Hero content: will be in the middle */}
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="is-size-1">
            Julia Blum
          </h1>
          <h2 className="subtitle">
            Hengellistä kirjallisuutta suomeksi
          </h2>
        </div>
      </div>
      {/* Hero footer: will stick at the bottom */}
      <div className="hero-foot">
        <nav className="tabs">
          <div className="container">
            {/* 
              <ul>
              <li className="is-active"><a>Overview</a></li>
              <li><a>Modifiers</a></li>
              </ul>
            */}
          </div>
        </nav>
      </div>
    </section>
  
    <div>{children}</div>
  </div>
)

export default TemplateWrapper
